/* eslint-disable no-unused-vars */

import React, { useState } from "react"
import scrollToElement from "scroll-to-element"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalOffices from "../components/globalOffices"
import ImageTween from "../components/imageTween"
import OfficesFloorSchema from "../components/officesFloorSchema"
import StoriesViewSvg from "../components/storiesViewSvg"
import PartnerModal from "../components/PartnerModal"

const Offices = () => {
  const lang = "en"
  const [tab, setTab] = useState("")
  const [floor, setfloor] = useState("1")

  const [state, setState] = React.useState({
    selectedSlot: { 0: "00", 1: "11" },
    cal: 1,
  })

  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false)

  const initialState = {
    tab: "",
    floor: "",
  }

  const clearState = () => {
    setState({ ...initialState })
  }

  return (
    <Layout lang={lang} translation="/pl/biura/">
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SEO title="Offices" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakow's Business Complex</h2>
          </div>
        </div>
      </section>
      <section className="offices-top global__padd--bigger">
        <span className="global__line"></span>
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1>Offices</h1>
          </div>
          <div className="col-md-6">
            <p>
              UNITY CENTRE are fully fitted, open plan office spaces in the city
              center, featuring stunning views. Find out more about available
              business lease.
            </p>
          </div>
        </div>

        <div className="offices-top__nav mobileview">
          <div className="row">
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("1")
                  setfloor("1")
                  scrollToElement("#view")
                  clearState()
                }}
                className={tab === "1" || floor === "1" ? "current" : ""}
              >
                Executive floors <span>UNITY Tower</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("11")
                  setfloor("11")
                  scrollToElement("#view")
                  clearState()
                }}
                className={tab === "11" || floor === "11" ? "current" : ""}
              >
                Corporate floors <span>UNITY Tower</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("2")
                  setfloor("2")
                  scrollToElement("#view")
                  clearState()
                }}
                className={tab === "2" || floor === "2" ? "current" : ""}
              >
                UNITY <span>Square One</span>
              </button>
            </div>
            <div className="col-sm-6">
              <button
                onClick={e => {
                  e.preventDefault()
                  setTab("3")
                  setfloor("3")
                  scrollToElement("#view")
                  clearState()
                }}
                className={tab === "3" || floor === "3" ? "current" : ""}
              >
                UNITY <span>Square Two</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section
        className="offices-view offices-top global__padd--bigger"
        id="view"
      >
        <div className="row align-items-center">
          <div className="col-xl-6">
            <h1>Floors: </h1>
            <br />
            <p>
              If you need more details, please select the specific floor you are
              interested in:
            </p>
          </div>
        </div>
      </section>
      <section className="offices-view" id="view">
        <div className="offices-view__wrapper global__padd--bigger">
          <div className="img__wrapper">
            <img src={require("../assets/images/store-view-new2.jpg")} alt="" />
            <StoriesViewSvg lang={lang} floor={floor} />

            <button
              className={`iconposition1 offices-view__wrapper-ico${
                tab === "1" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("1")
              }}
            >
              <span>
                UNITY Tower <br />
                Executive floors
                <p>
                  26 floors + ground floor
                  <br />
                  17 500 sqm GLA - total building area
                  <br />
                  586* - 621 sqm GLA - average floor size
                  <br />
                  1 500 sqm** - retail area
                  <br />
                  10 lifts
                  <br />
                  2 staircases
                  <br />
                  3,2m (2,7m) - floor height
                </p>
                <p>
                  * floors 22-24
                  <br />
                  ** ground floor & 1st floor
                </p>
              </span>
            </button>
            <button
              className={`iconposition2 offices-view__wrapper-ico${
                tab === "3" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("3")
              }}
            >
              <span>
                UNITY Square Two
                <p>
                  8 floors + ground floor <br />
                  7 300 sqm GLA - total building area <br />
                  746 sqm GLA - average floor size <br />
                  2 080 sqm***** - retail area <br />
                  4 lifts <br />
                  2 staircases <br />
                  3,27m (2,77m) - floor height
                </p>
                <p>***** ground floor & 1st floor</p>
              </span>
            </button>
            <button className={`iconposition3 offices-view__wrapper-ico`}>
              <span className="custom-narrow">
                Radisson RED
                <br />
                coming Summer 2024
              </span>
            </button>
            <button
              className={`iconposition4 offices-view__wrapper-ico${
                tab === "11" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("11")
              }}
            >
              <span>
                UNITY Tower <br /> Corporate floors
                <br />
                <p>
                  26 floors + ground floor
                  <br />
                  17 500 sqm GLA - total building area
                  <br />
                  817*** - 1 082**** Sqm GLA - average floor size
                  <br />
                  1 500 sqm** - retail area
                  <br />
                  10 lifts
                  <br />
                  2 staircases
                  <br />
                  3,2m (2,7m) - floor height
                </p>
                <p>
                  ** ground floor & 1st floor
                  <br />
                  *** 2nd floor
                  <br />
                  **** floors 3-7
                </p>
              </span>
            </button>
            <button
              className={`iconposition5 offices-view__wrapper-ico${
                tab === "2" ? " current" : ""
              }`}
              onClick={e => {
                e.preventDefault()
                setTab("2")
              }}
            >
              <span>
                UNITY Square One
                <p>
                  5 floors + ground floor <br />
                  9 980 sqm GLA - total building area
                  <br />
                  1 869 sqm GLA - average floor size <br />
                  1 582 sqm - retail area
                  <br />
                  3 lifts <br />
                  2 staircases <br />
                  3,2m (2,7m) - floor height
                </p>
              </span>
            </button>
            <button className={`iconposition6 offices-view__wrapper-ico`}>
              <span className="custom-narrow">
                RADISSON RED Apartments
                <br />
                coming Summer 2024
              </span>
            </button>
          </div>
        </div>
      </section>

      <section className="offices-floor-schema global__padd--bigger">
        <span className="global__line"></span>
        <OfficesFloorSchema lang={lang} tab={tab} />
      </section>

      <section className="offices-content global__padd--bigger">
        {tab === "1" || tab === "11" ? (
          <div className="row no-gutters">
            <div className="col-md-5">
              <ImageTween
                src={require("../assets/images/offices-content-image-tower.jpg")}
                srcSet={require("../assets/images/offices-content-image-tower@2x.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="offices-content__inner sticky-wrapper">
                <h3>
                  The prestigious office spaces await the hustle and bustle of
                  doing business in the center of the city.
                </h3>
                <p>
                  A 26-floor, 17 500 SQM “AA” class office building. These
                  premises will suit the discerning standards of companies
                  wanting to make a statement with their work environment and
                  attract new clients and talents. With human-centred design,
                  light-lifted spaces, contemporary fit outs and a fresh design,
                  this will be an on-demand location for companies at the top of
                  the game.
                </p>
              </div>
            </div>
          </div>
        ) : tab === "2" ? (
          <div className="row no-gutters">
            <div className="col-md-5">
              <ImageTween
                src={require("../assets/images/offices-content-image-square-one.jpg")}
                srcSet={require("../assets/images/offices-content-image-square-one@2x.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="offices-content__inner sticky-wrapper">
                <h3>
                  The prestigious office spaces await the hustle and bustle of
                  doing business in the center of the city.
                </h3>
                <p>
                  A 5 floor (+ground floor), 10 700 SQM “AA” class office
                  building designed for maximum tenant flexibility. This is
                  where the future starts. The offices are destined for cutting
                  edge concepts, limitless perspectives and maverick ideas
                  fuelled by large, almost columnless floorplates.
                </p>
              </div>
            </div>
          </div>
        ) : tab === "3" ? (
          <div className="row no-gutters">
            <div className="col-md-5">
              <ImageTween
                src={require("../assets/images/offices-content-image-square-two.jpg")}
                srcSet={require("../assets/images/offices-content-image-square-two@2x.jpg")}
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="offices-content__inner sticky-wrapper">
                <h3>
                  The prestigious office spaces await the hustle and bustle of
                  doing business in the center of the city.
                </h3>
                <p>
                  A 9 floor, 7 200 SQM “AA” class office building. The highest
                  standards with ca. 700 SQM footplates, with no columns fit,
                  designed for the future of the workplace with a Krakow’s top
                  address. This is a contemporary environment that offers
                  flexible interiors and openness to greenmarks to promote
                  collaboration and foster wellbeing with some perks such as the
                  best view of Old Town from the upper floors.
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>

      <GlobalOffices lang={lang} color="blue" />

      <section className="offices-icons global__padd--bigger">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-1.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Offices with the most beautiful view of Krakow</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-2.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>A panoramic terrace 100 m above the ground</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-3.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Flexible office space</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-4.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Luxurious apartments</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-5.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Conference and training centre</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-6.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>24/7 security</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-7.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Two-level underground parking lots</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-8.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Parking spaces for charging electric cars</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-9.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Innovative parking system</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-10.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>
                  Newly designed city boulevard available to the general public
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-11.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>200 cycle racks/ City bikes</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-12.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Modern cloakrooms and showers</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-13.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Rich commercial infrastructure</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-14.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Easy access to banking services</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-15.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Certified green building</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-16.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Openable windows</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-19.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Solar panels</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-20.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>Green Energy</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-17.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>
                  Diversity of restaurants and cafes just a few steps away
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="offices-icons__tile">
              <div className="offices-icons__tile-image">
                <img
                  src={require("../assets/images/offices-content-ico-18.svg")}
                  alt=""
                />
              </div>
              <div className="offices-icons__tile-inner">
                <h4>
                  The building was designed taking into account the “Design for
                  All” ideas catering to the needs of disabled.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="offices-community">
        <div className="global__padd--bigger">
          <h1>Community Strive to inspire</h1>
          <div className="offices-community__wrapper">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6">
                <div className="offices-community__logos">
                  <button
                    className="offices-community__toggle"
                    onClick={() => setIsPartnerModalOpen(true)}
                  >
                    <img
                      className="img-fluid"
                      src={require("../assets/images/offices-logo-miele.svg")}
                      alt="Miele"
                    />
                  </button>
                  <a
                    className="offices-community__link"
                    href="https://www.autodesk.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../assets/images/autodesk-logo.png")}
                      alt="Autodesk"
                    />
                  </a>
                  <a
                    className="offices-community__link"
                    href="https://www.radissonhotels.com/pl-pl/hotele/radisson-red-krakow?facilitatorId=RHGSEM&cid=a:ps+b:ggl+c:emea+i:brand+e:rdr+d:cese+r:brt+f:pl-PL+g:ho+h:PLKRK3+v:cf&gad_source=1&gclid=EAIaIQobChMIooK3o-nHiAMVjDcGAB2dQi4yEAAYASAAEgIMAPD_BwE&gclsrc=aw.ds"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../assets/images/KRKRR_HOTEL-LOGO_RGB.png")}
                      alt="Radisson Hotels"
                    />
                  </a>
                  <a
                    className="offices-community__link"
                    href="https://www.enterpriserentacar.pl/en/home.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../assets/images/07_ERAC_key_RGB.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <p>
                  UNITY CENTRE aims to resonate with a vibrant mix of tenants.
                  These companies have found a shared innovative DNA, as well as
                  opportunities to grow and attract best talents, deciding to
                  locate here, where the future starts in the building’s
                  expanding community. Discover why companies of all sizes and
                  industries call UNITY CENTRE their new home.
                </p>
              </div>
            </div>
          </div>
          <div className="offices-community__images">
            <div className="row align-items-center">
              <div className="col-sm-4">
                <ImageTween
                  src={require("../assets/images/offices-content-image-5@2x.jpg")}
                  alt=""
                />
              </div>
              <div className="col-sm-3">
                <ImageTween
                  src={require("../assets/images/offices-content-image-4@2x.jpg")}
                  alt=""
                />
              </div>
              <div className="col-sm-5">
                <ImageTween
                  src={require("../assets/images/offices-content-image-6@2x.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {isPartnerModalOpen && (
        <PartnerModal
          image={require("../assets/images/miele-map.jpg")}
          title="Miele Experience Center Kraków"
          descriptions={[
            {
              title: "Address",
              content:
                "<p>UNITY Square One<br/>ul. Lubomirskiego 24<br/>31-509 Kraków</p>",
            },
            {
              title: "Opening hours",
              content:
                "<p>Mondays and Wednesdays 9:00 – 18:00<br/>Tuesdays and Thursdays 10:00 – 18:00<br/>Fridays 10:00 – 16:00<br/>Saturdays 10:00 – 15:00</p>",
            },
            {
              title: "Phone number",
              content: '<p><a href="tel:+48881090452">+48 881 090 452</a></p>',
            },
          ]}
          onClose={() => setIsPartnerModalOpen(false)}
        />
      )}
    </Layout>
  )
}

export default Offices
